import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    metaInfo: {},//网站三要素
    token:'', //token

  },
  getters: {
    getToken(state) {
      return state.token
    }
  },
  mutations: {
    CAHNGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    },
    //token
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {

  },
  modules: {

  }
})
