import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/index',
        meta: {
            metaInfo: {
                title: "首页",
                keywords: "开芯网 ",
                description: "开芯网"
            }
        },
        component: () => import( '@/views/home'),
        children: [{
            //  首页
            path: '/index',
            name: 'index',
            component: () => import( '@/views/index')
        },
            {
                // 文档
                path: '/document',
                name: 'document',
                component: () => import( '@/views/document')
            },
            {
                // 文档详情
                path: '/documentcontent',
                name: 'documentcontent',
                component: () => import( '@/views/documentcontent')
            },
            {
                // DIY
                path: '/DIY',
                name: 'DIY',
                component: () => import( '@/views/DIY')
            },
            {
                // 百货
                path: '/Store',
                name: 'Store',
                component: () => import( '@/views/Store')
            },
            {
                // 二手市场
                path: '/secondhand',
                name: 'secondhand',
                component: () => import( '@/views/secondhand')
            },
            {
                // 媒体中心
                path: '/medium',
                name: 'medium',
                component: () => import( '@/views/medium')
            },
            {
                // 购物车
                path: '/ShoppingCart',
                name: 'ShoppingCart',
                component: () => import( '@/views/ShoppingCart')
            },
            {
                // 商品详情
                path: '/shangpinxiangqing',
                name: 'shangpinxiangqing',
                component: () => import( '@/views/shangpinxiangqing')
            },
            {
                // 立即购买
                path: '/ljgm',
                name: 'ljgm',
                component: () => import( '@/views/ljgm')
            },
            {
                // 提交订单
                path: '/tjdd',
                name: 'tjdd',
                component: () => import( '@/views/tjdd')
            },
            {
                // 提交订单
                path: '/tjddgood',
                name: 'tjddgood',
                component: () => import( '@/views/tjddgood')
            },
            {
                // 提交订单
                path: '/tjdddocument',
                name: 'tjdddocument',
                component: () => import( '@/views/tjdddocument')
            },
            {
                // 订单信息
                path: '/ddxx',
                name: 'ddxx',
                component: () => import( '@/views/ddxx')
            },
            {
                // 支付页面
                path: '/ZF',
                name: 'ZF',
                component: () => import( '@/views/ZF')
            },
            {
                // 支付页面
                path: '/aly',
                name: 'aly',
                component: () => import( '@/views/aly')
            },
            {
                // 我的
                path: '/my',
                name: 'my',
                redirect: '/myindex',
                component: () => import( '@/views/my'),
                children: [
                    {
                        path: '/myindex',
                        name: 'myindex',
                        component: () => import( '@/views/myindex'),
                    },
                    //  身份认证
                    {
                        path: '/shenfenrenzheng',
                        name: 'shenfenrenzheng',
                        component: () => import( '@/views/shenfenrenzheng'),
                    },
                    //积分提现
                    {
                        path: '/my-jifentixian',
                        name: 'my-jifentixian',
                        component: () => import( '@/views/my-jifentixian'),
                    },    //成交额提现
                    {
                        path: '/my-cjrtx',
                        name: 'my-cjrtx',
                        component: () => import( '@/views/my-cjrtx'),
                    },
                    // 质保金
                    {
                        path: '/my-zhibaojin',
                        name: 'my-zhibaojin',
                        component: () => import( '@/views/my-zhibaojin'),
                    },
                    //   首创保证金
                    {
                        path: '/my-scbaozhengjin',
                        name: 'my-scbaozhengjin',
                        component: () => import( '@/views/my-scbaozhengjin'),
                    },
                    //   成交额提现
                    {
                        path: '/my-cjrtx',
                        name: 'my-cjrtx',
                        component: () => import( '@/views/my-cjrtx'),
                    },
                    //  文档会员
                    {
                        path: '/my-vip',
                        name: '/my-vip',
                        component: () => import( '@/views/my-vip'),
                    },
                    //  已下载
                    {
                        path: '/my-yixiazai',
                        name: '/my-yixiazai',
                        component: () => import( '@/views/my-yixiazai'),
                    },
                    //  收藏
                    {
                        path: '/my-shoucang',
                        name: 'my-shoucang',
                        component: () => import( '@/views/my-shoucang'),
                    },
                    //  已上传
                    {
                        path: '/my-yishangchuan',
                        name: 'my-yishangchuan',
                        component: () => import( '@/views/my-yishangchuan'),
                    },
                    //  我的订单
                    {
                        path: '/my-wodingdan',
                        name: 'my-wodingdan',
                        component: () => import( '@/views/my-wodingdan'),
                    },
                    // 产品管理
                    {
                        path: '/my-cpgl',
                        name: 'my-cpgl',
                        component: () => import( '@/views/my-cpgl'),
                    },
                    // 百货管理
                    {
                        path: '/my-mhgl',
                        name: 'my-mhgl',
                        component: () => import( '@/views/my-mhgl'),
                    },
                    // 百货管理
                    {
                        path: '/my-lf',
                        name: 'my-lf',
                        component: () => import( '@/views/my-lf'),
                    },
                    // 二手市场管理
                    {
                        path: '/my-rssc',
                        name: 'my-rssc',
                        component: () => import( '@/views/my-rssc'),
                    },
                    // diy管理
                    {
                        path: '/my-diy',
                        name: 'my-diy',
                        component: () => import( '@/views/my-diy'),
                    },
                    // 收货地址
                    {
                        path: '/my-shdz',
                        name: 'my-shdz',
                        component: () => import( '@/views/my-shdz'),
                    },
                    // 个人信息
                    {
                        path: '/my-grxx',
                        name: 'my-grxx',
                        component: () => import( '@/views/my-grxx'),
                    },
                ]

            },
            //  我的-身份认证
            {
                path: '/my-shenfen',
                name: 'my-shenfen',
                component: () => import( '@/views/my-shenfen'),
            },
            //  我的-文档会员认证
            {
                path: '/my-wdhyrz',
                name: 'my-wdhyrz',
                component: () => import( '@/views/my-wdhyrz'),
            },
            //  媒体详情
            {
                path: '/medium-details',
                name: 'medium-details',
                component: () => import( '@/views/medium-details')
            },    //  媒体详情
            {
                path: '/pages',
                name: 'pages',
                component: () => import( '@/views/pages')
            },
            //  上传文档
            {
                path: '/Schuanwenjian',
                name: 'Schuanwenjian',
                component: () => import( '@/views/Schuanwenjian')
            },
            //  修改文档
            {
                path: '/Xgaiwenjian',
                name: 'Xgaiwenjian',
                component: () => import( '@/views/Xgaiwenjian')
            },
            //  上传商品
            {
                path: '/scssp',
                name: 'scssp',
                component: () => import( '@/views/scssp')
            },
            //  上传商品
            {
                path: '/Xscssp',
                name: 'Xscssp',
                component: () => import( '@/views/Xscssp')
            },
            //  商品
            {
                path: '/Sshangpin',
                name: 'Sshangpin',
                component: () => import( '@/views/Sshangpin')
            }

        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            metaInfo: {
                title: "登录",
                keywords: "开芯网",
                description: "开芯网"
            }
        },

        component: () => import( '../views/login.vue')
    },
    {
        path: '/ceshi',
        name: 'ceshi',
        meta: {
            metaInfo: {
                title: "404",
                keywords: "开芯网404",
                description: "开芯网404"
            }
        },

        component: () => import( '../views/ceshi.vue')
    }

]

const router = new VueRouter({
    // mode:'history',
    routes
})

export default router
// router.beforeEach(async (to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next();
// });

