import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/font_4013984_3ywclb0vrci/iconfont.css'
import VueWechatTitle from 'vue-wechat-title'
import {
    Base64
} from 'js-base64';
import JsonExcel from 'vue-json-excel'// 引入导出Excel
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$Base64 = Base64
Vue.use(VueWechatTitle)
import Meta from 'vue-meta';
Vue.use(ElementUI);
Vue.use(Meta);


// Vue.prototype.upimg = '/api/UploadImg'
Vue.prototype.upimg = '/UploadImg'
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if (to.meta.metaInfo) {
        store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
    }
    next()
});

new Vue({
    router,
    store,

    metaInfo() {
        return {
            title: this.$store.state.metaInfo.title,
            meta: [{
                name: "keywords",
                content: this.$store.state.metaInfo.keywords
            }, {
                name: "description",
                content: this.$store.state.metaInfo.description
            }]
        }
    },
    render: h => h(App)
}).$mount('#app')
